import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import loadImage from '../images/giphy.gif';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [loader, setLoader] = useState(true);
  const [imageUrl, setImageUrl] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const callApiUserProfile = async (userId) => {
    try {
      setLoader(true);
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken'),
      };
      const url = `${process.env.REACT_APP_API_URL}users/searchuser/${userId}`;
      const response = await axios.get(url, { headers });
      setUser(response.data[0]);
      setLoader(false);

      if (response.data[0]) {
        const imageResponse = await axios.get(`${process.env.REACT_APP_API_URL}course/userDetails/${userId}`, { headers });
        if (imageResponse.data.profile_image) {
          const fullImageUrl = `${process.env.REACT_APP_API_URL}${imageResponse.data.profile_image.replace(/\\/g, '/')}`;
          setImageUrl(fullImageUrl);
        } else {
          setImageUrl('');
        }
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    const userId = localStorage.getItem('userid');
    if (userId) {
      callApiUserProfile(userId);
    } else {
      setLoader(false);
    }
  }, []);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const userId = localStorage.getItem('userid');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}course/userDetails/uploads/profile/${userId}`,
        formData,
        {
          headers: {
            'accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'x-access-token': localStorage.getItem('jwttoken')
          }
        }
      );
      console.log('File upload response:', response.data);
      alert('File uploaded successfully');
      setShowProfileModal(false);
      callApiUserProfile(userId);
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Failed to upload file');
    }
  };

  const handlePasswordUpdate = async () => {
    if (newPassword !== confirmPassword) {
      alert('Passwords do not match.');
      return;
    }

    try {
      const userId = localStorage.getItem('userid');
      const headers = {
        'x-access-token': localStorage.getItem('jwttoken'),
      };
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}users/updateuserpassword`,
        { username: user.username, password: newPassword },
        { headers }
      );
      console.log('Password update response:', response.data);
      alert('Password updated successfully');
      setShowPasswordModal(false);
    } catch (error) {
      console.error('Error updating password:', error);
      alert('Failed to update password');
    }
  };

  const handleProfileModalClose = () => {
    setShowProfileModal(false);
  };

  const handleProfileModalShow = () => {
    setShowProfileModal(true);
  };

  const handlePasswordModalClose = () => {
    setShowPasswordModal(false);
  };

  const handlePasswordModalShow = () => {
    setShowPasswordModal(true);
  };

  const handleClick = () => {
    navigate('/userprofile', {
      state: { user },
    });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f0f0f0' }}>
      {loader ? (
        <div style={{ textAlign: 'center' }}>
          <img src={loadImage} alt="Loader" />
        </div>
      ) : user ? (
        <div style={{ background: '#fff', border: '1px solid #ccc', borderRadius: '8px', padding: '32px', maxWidth: '600px', width: '100%', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', textAlign: 'center' }}>
          <div style={{ marginBottom: '24px' }}>
            <img
              src={imageUrl || 'default_profile_image_url'}
              alt="Profile"
              style={{ width: '200px', height: '200px', borderRadius: '50%', objectFit: 'cover' }}
            />
            <h2>{user.name}</h2>
            <p>{user.email}</p>
          </div>
          <div>
            <p><strong>User ID:</strong> {user.userid}</p>
            <p>
              <strong>Username:</strong>
              <span style={{ display: 'block', maxWidth: '100%', overflow: 'visible', textOverflow: 'clip', whiteSpace: 'normal' }}>
                {user.username}
              </span>
            </p>
            <p><strong>Mobile:</strong> {user.mobile}</p>
          </div>
          <div style={{ marginTop: '24px' }}>
            <Button variant="primary" onClick={handleProfileModalShow}>
              Change Profile Image
            </Button>
            <Button variant="secondary" onClick={handlePasswordModalShow} style={{ marginLeft: '10px' }}>
              Update Password
            </Button>
          </div>

          {/* Profile Image Modal */}
          <Modal show={showProfileModal} onHide={handleProfileModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Update Profile Photo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input type="file" name="file" onChange={handleFileChange} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleProfileModalClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleFileUpload}>
                Upload
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Update Password Modal */}
          <Modal show={showPasswordModal} onHide={handlePasswordModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Update Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter new password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm new password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handlePasswordModalClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handlePasswordUpdate}>
                Update
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ) : (
        <p>User data not found. Please log in again.</p>
      )}
    </div>
  );
};

export default UserProfile;
