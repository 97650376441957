import React, { useEffect, useState } from 'react';
import './App.css';
import axios from 'axios';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './template.css';
import './style.css';
import './cs-skin-elastic.css';
import DataApp from './DataContext';

import SecureHeader from './shared/SecureHeader';
import SecureFooter from './shared/SecureFooter';
import SecureLeftPanel from './shared/SecureLeftPanel';
import SecureRightPanel from './shared/SecureRightPanel';
import Header from './shared/Header';
import Footer from './shared/Footer';
import LeftPanel from './shared/LeftPanel';
import StudentStatus from './student/StudentStatus';

import Home from './common/Home';
import Login from './common/Login';
import DashboardAdmin from './admin/DashboardAdmin';
import UsersList from './admin/UsersList';
import UserAdd from './admin/UserAdd';
import UserUpdate from './admin/UserUpdate';
import MernList from './admin/MernList';
import ClassList from './admin/ClassList';
import ClassUpdate from './admin/ClassUpdate';
import ClassAdd from './admin/ClassAdd';

import MernRegister from './student/MernRegister';
import Dashboard from './student/Dashboard';
import Classes from './student/Classes';
import Assignment from './student/Assignment';
import AssignmentAll from './student/AssignmentAll';
import Quiz from './student/Quiz';
import Leaderboard from './student/Leaderboard';
import AttendanceMern from './admin/AttendanceMern';

import Test from './admin/Test';
import AssignmentDetail from './student/AssignmentDetail';
import AssignmentSubmit from './student/AssignmentSubmit';

import AssignmentList from './admin/AssignmentList';
import AssignmentAdd from './admin/AssignmentAdd';
import AssignmentUpdate from './admin/AssignmentUpdate';
import SyllabusList from './admin/SyllabusList';
import SyllabusAdd from './admin/SyllabusAdd';
import SyllabusUpdate from './admin/SyllabusUpdate';
import AssignmentAnswerList from './admin/AssignmentAnswerList'
import ThreetableUpdate from './admin/threetableupdate';
import UpdateRegisteredUsers from './admin/UpdateRegisteredUsers'
import Users_Registered from './admin/Users_Registered'
import Users_Registered_Details from './admin/Users_Registered_Details'
import MarksExtra from './admin/MarksExtra';
import MyallClassessandAttaindence from '../src/student/MyallClassessandAttaindence'
import AllStatus from '../src/student/AllStatus';
import AccessDenied from './common/AccessDenied';
import Mocks from './mock/Mocks';
import MockDetail from './mock/MockDetail';
import UserProfile from '../src/student/UserProfile';

import OfferLetter from './admin/OfferLetter';
import OfferLetterMba from './admin/OfferLetterMba';
import InternsList from './admin/InternsList';
import ExperienceLetter from './admin/ExperienceLetter';
import MbaInternsList from './admin/MbaInternsList';
import OfflineOfferLetter from './admin/OfflineOfferLetter';
import AllClasses from './admin/AllClasses';
import ExperienceLetterMBA from './admin/ExperienceLetterMBA';
import SettingList from './admin/SettingList';
import SettingAdd from './admin/SettingAdd ';
import SettingUpdate from './admin/SettingUpdate';
import ExpLetterDesign from './admin/ExpLetterDesign';

function App() {
  //temp code to keep local server live
  // const callApiQsList = async () => {
  //   try {
  //     const headers = {
  //       'x-access-token': localStorage.getItem('jwttoken')
  //     };
  //     const url = process.env.REACT_APP_API_URL + 'syllabus/getAllActiveSyllabus';
  //     const response = await axios.get(url, { headers });
  //   }
  //   catch (error) {
  //     // console.log(error); 
  //   }
  // }
  // useEffect(() => {
  //   setInterval(() => callApiQsList(), 10000)
  // }, [])
  //temp code to keep server live


  const [isMobileMenu, setMobileMenu] = useState(false);


  const clearStorage = () => {
    window.localStorage.clear();
  }

  useEffect(() => {
    //clearStorage();
  }, [])

  // useEffect(() => {
  //   console.log(' mobilemenu stats - ', isMobileMenu);
  // }, [isMobileMenu])



  return (
    <>
      <BrowserRouter>
        <DataApp>
          <>
            <LeftPanel isMobileMenu={isMobileMenu} setMobileMenu={setMobileMenu} />
            <div id="right-panel" class="right-panel">
              <Header isMobileMenu={isMobileMenu} setMobileMenu={setMobileMenu} />
              <div className='content'>
                <Routes>
                  <Route path='/dashboardadmin' element={<DashboardAdmin />} />
                  <Route path='/login' element={<Login />} />
                  <Route path='/home' element={<Home />} />
                  <Route path='/userslist' element={<UsersList />} />
                  <Route path='/useradd' element={<UserAdd />} />
                  <Route path='/useredit/:id' element={<UserUpdate />} />
                  <Route path='/mernuserslist' element={<MernList />} />
                  <Route path='/mernregister' element={<MernRegister />} />
                  <Route path='/batchclass' element={<Classes />} />
                  <Route path='/classlist' element={<ClassList />} />
                  <Route path='/allclasses' element={<AllClasses />} />
                  <Route path='/classadd' element={<ClassAdd />} />
                  <Route path='/classedit/:classid' element={<ClassUpdate />} />

                  <Route path='/assignmentslist' element={<AssignmentList />} />

                  <Route path='/assignmentsadd' element={<AssignmentAdd />} />
                  <Route path='/assignmentsUpdate/:id' element={<AssignmentUpdate />} />
                  <Route path='/assignmentanswerlist/' element={<AssignmentAnswerList />} />
                  <Route path='/updateregisteredusers/' element={<UpdateRegisteredUsers />} />
                  <Route path='/threetableupdate/:userid' element={<ThreetableUpdate />} />
                  <Route path='/users_registered' element={<Users_Registered />} />
                  <Route path='/users_registered_detail/:id' element={<Users_Registered_Details />} />
                  <Route path='/internslist' element={<InternsList />} />
                  <Route path='/mbainternslist' element={<MbaInternsList />} />

                  <Route path='/assignmentall' element={<AssignmentAll />} />
                  <Route path='/assignment' element={<Assignment />} />
                  <Route path='/assignment/:assgid' element={<AssignmentDetail />} />
                  <Route path='/assignmentsubmit/:assgid' element={<AssignmentSubmit />} />
                  <Route path='/SyllabusList' element={<SyllabusList />} />
                  <Route path='/Syllabusadd' element={<SyllabusAdd />} />
                  <Route path='/Syllabusedit/:id' element={<SyllabusUpdate />} />
                  <Route path='/quiz' element={<Quiz />} />
                  <Route path='/leaderboard' element={<Leaderboard />} />
                  <Route path='/attendancemern' element={<AttendanceMern />} />
                  <Route path='/myStatus' element={<StudentStatus />} />
                  <Route path='/userprofile' element={<UserProfile />} />

                  <Route path='/dashboard' element={<Dashboard />} />
                  <Route path='/test' element={<Test />} />
                  <Route path='/marksextra' element={<MarksExtra />} />
                  <Route path='/allStatus' element={<AllStatus />} />
                  <Route path='/myStatus/:username' element={<MyallClassessandAttaindence />} />
                  <Route path='/accessdenied' element={<AccessDenied />} />
                  <Route path='/mocks' element={<Mocks />} />
                  <Route path='/mocks/:mockid' element={<MockDetail />} />

                  <Route path='/offerletter/:userid' element={<OfferLetter />} />
                  <Route path='/offerlettermba/:userid' element={<OfferLetterMba />} />
                  <Route path='/offlineofferletter/:userid' element={<OfflineOfferLetter />} />
                  <Route path='/experienceletter/:userid' element={<ExperienceLetter />} />
                  <Route path='/experiencelettermba/:userid' element={<ExperienceLetterMBA />} />
                  <Route path='/expletterdesign/:userid' element={<ExpLetterDesign />} />

                <Route path='/settinglist' element={<SettingList/>} />
                  <Route path='/addsetting' element={<SettingAdd/>} />
                  <Route path='/updatesetting/:setting_id' element={<SettingUpdate />} />
                  <Route path='/' element={<Login />} />
                </Routes>
              </div>
              <Footer />
            </div>
          </>
        </DataApp>
      </BrowserRouter>

    </>
  );
}

export default App;
