
import React from 'react'
import axios from 'axios';
import { Formik, Field, Form } from 'formik'
import { useNavigate } from 'react-router';
const ClassAdd = () => {
  const formValues = {
    classname: "", coursename: "", startdate: "", starttime: "", enddate: "", claslink: "", videolink: "", quizid: "",
    topicid: "",
  };
  const navigate = useNavigate();
  const makeApiCall = async (values) => {
    const headers = {
      'x-access-token': localStorage.getItem('jwttoken')
    };
    const url = process.env.REACT_APP_API_URL + 'classes/addclasses';
    const response = await axios.post(url, values, { headers });
    //const response = await fetch(url);
    // console.log(response);
    alert('Class Added Successfully');
    navigate('/classlist');

  }
  return (
    <div>
      <h4 className=''>Add New Class</h4>
      <Formik initialValues={formValues} onSubmit={
        async (values) => {
          makeApiCall(values);
        }
      }>
        <div className='form-container'>
          <Form className=''>
            <div>
              <label>Class Name</label>
              <Field name="classname" type="text" />
            </div>
            <div>
              <label>Course Name</label>
              <Field name="coursename" type="text" />
            </div>
            <div>
              <label>Start Date</label>
              <Field name="startdate" type="date" />
            </div>
            <div>
              <label>Start Time</label>
              <Field name="starttime" type="time" />
            </div>
            <div>
              <label>End Date</label>
              <Field name="enddate" type="date" />
            </div>
            <div>
              <label>Class Link</label>
              <Field name="classlink" type="text" />
            </div>
            <div>
              <label>Video Link</label>
              <Field name="videolink" type="text" />
            </div>
            <div>
              <label>Quiz Id</label>
              <Field name="quizid" type="number" />
            </div>
            <div>
              <label>Topic Id</label>
              <Field name="topicid" type="text" />
            </div>
            <div>
              <button type="submit" className='btn btn-success'>Create Class</button>
            </div>
          </Form>
        </div>


      </Formik>
    </div>
  )
}

export default ClassAdd