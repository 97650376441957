// VideoModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const getYouTubeEmbedUrl = (url) => {
  const regex = /^.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regex);
  return match && match[1] ? `https://www.youtube.com/embed/${match[1]}` : url;
};

const VideoModal = ({ show, handleClose, videoUrl }) => {
  const embedUrl = getYouTubeEmbedUrl(videoUrl);

  return (
    <Modal show={show} onHide={handleClose} fullscreen centered size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header>
        <Modal.Title className="w-100 d-flex justify-content-between align-items-center">
          <span>Class Recording</span>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
          <iframe
            src={embedUrl}
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            allowFullScreen
            title="Class Recording"
          ></iframe>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VideoModal;
