import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  FaBuffer,
  FaAlignJustify,
  FaBook,
  FaCalendarAlt,
} from "react-icons/fa";
import loadImage from "../images/giphy.gif";

const Dashboard = () => {
  const userid = window.localStorage.getItem("userid");
  const usertype = window.localStorage.getItem("usertype");

  const [userdetails, setUserdetails] = useState([]);
  const [loader, setLoader] = useState(true);

  const headers = {
    'x-access-token': localStorage.getItem('jwttoken')
  };

  const fetchUserDetails = async () => {
    try {
      setLoader(true);
      const url =
        process.env.REACT_APP_API_URL + "users/searchmernuser/" + userid;
      const response = await axios.get(url, { headers });
      // console.log(response);
      setUserdetails(response.data[0]);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  return (
    <div>
      {loader ? (
        <div className=" col-8 loading">
          <img src={loadImage} alt="Loader" />
        </div>
      ) : (
        <>
          <div>
            <h2>Welcome, {userdetails && userdetails.name}</h2>
            <br></br>
            <div class="row">
              {usertype !== "mock" && (
                <>
                  <div class="col-sm-6 col-lg-6">
                    <Link to="/allclasses" className="link-success">
                      <div class="card text-white bg-flat-color-1">
                        <div class="card-body">
                          <div class="card-left pt-1 float-left">
                            <h3 class="mb-0 fw-r">
                              <span class="currency float-left mr-1">
                                Classes
                              </span>
                            </h3>
                            <br></br>
                            <br></br>
                            <p class="text-light mt-1 m-0">
                              Join your classes here
                            </p>
                          </div>

                          <div class="card-right float-right text-right">
                            <FaAlignJustify />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div class="col-sm-6 col-lg-6">
                    <Link to="/assignment" className="link-danger">
                      <div class="card text-white bg-flat-color-6">
                        <div class="card-body">
                          <div class="card-left pt-1 float-left">
                            <h3 class="mb-0 fw-r">
                              <span class="count float-left">Assignments</span>
                            </h3>
                            <br></br>
                            <br></br>
                            <p class="text-light mt-1 m-0">Live Now</p>
                          </div>

                          <div class="card-right float-right text-right">
                            <FaBook />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </>
              )}

              <div class="col-sm-12 col-lg-12">
                <Link to="/mocks" className="link-danger">
                  <div class="card text-white bg-flat-color-3">
                    <div class="card-body">
                      <div class="card-left pt-1 float-left">
                        <h3 class="mb-0 fw-r">
                          <span class="count">Mocks</span>
                        </h3>
                        <br></br>
                        <p class="text-light mt-1 m-0">Coming Soon...</p>
                      </div>

                      <div class="card-right float-right text-right">
                        <FaBuffer />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              {/* <div class="col-sm-6 col-lg-3">
            <Link to='/leaderboard' className='link-danger'>
              <div class="card text-white bg-flat-color-3">
                <div class="card-body">
                  <div class="card-left pt-1 float-left">
                    <h3 class="mb-0 fw-r">
                      <span class="count">Leaderboard</span>
                    </h3>
                    <br></br>
                    <p class="text-light mt-1 m-0">Coming Soon...</p>
                  </div>

                  <div class="card-right float-right text-right">
                    <FaBuffer />
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div class="col-sm-6 col-lg-3">
            <Link to='/mystatus' className='link-danger'>
            <div class="card text-dark bg-warning">
                <div class="card-body">
                  <div class="card-left pt-1 float-left">
                    <h3 class="mb-0 fw-r">
                      <span class="count">My Status</span>
                    </h3>
                    
                  </div>

                  <div class="card-right float-right text-right">
                    <FaBuffer />
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div class="col-sm-6 col-lg-3">
            <Link to='/quiz' className='link-danger'>
              <div class="card text-white bg-flat-color-2">
                <div class="card-body">
                  <div class="card-left pt-1 float-left">
                    <h3 class="mb-0 fw-r">
                      <span class="count">Quiz</span>
                    </h3>
                    <br></br>
                    <p class="text-light mt-1 m-0">Coming Soon...</p>
                  </div>

                  <div class="card-right float-right text-right">
                    <FaCalendarAlt />
                  </div>
                </div>
              </div>
            </Link>
          </div> */}
            </div>

            {/* <div className='row'>
          <div className='col-12'>
            <div class="alert alert-success" role="alert">
              Please, submit feedback after class to mark your attendance.
            </div>
          </div>
        </div> */}
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
